import React from 'react';
import './App.css';

type AppState = {
  listing: DirContents | null
  balances: BalanceResponse | null
  time: number
  partialPassword: string
}

const REMOTE_HOST = `https://api.abundanceofrain.net/${window.localStorage.getItem('password') || ''}`;
// const REMOTE_HOST = `http://localhost:3030/${window.localStorage.getItem('password') || ''}`;

type DirContents = string | { name: string, contents: DirContents[] }

type CruResponse = { success: true, data: any } | { success: false, message: string }

type BalanceResponse = {
  time: string
  balances: Balances
}

type Balances = { [ex: string]: { [account:string]: { [asset:string]: number }}}

async function fetchJson(path: string): Promise<any> {
  let url = `${REMOTE_HOST}/${path}`;
  let response = await fetch(url, {cache: "no-store"});
  let result: CruResponse = await response.json();
  if (result.success) {
    return result.data;
  } else {
    throw new Error(result.message);
  }
}

function paths(contents: DirContents): string[] {
  if (typeof contents === "string") {
    return [contents];
  }
  let result: string[] = []
  for (let subdir of contents.contents) {
    for (let path of paths(subdir)) {
      result.push(`${contents.name}/${path}`);
    }
  }
  return result
}

function sleep(seconds: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, seconds * 1000));
}

async function every(seconds: number, callback: () => void) {
  while (true) {
    callback();
    await sleep(seconds);
  }
}

class App extends React.Component<{}, AppState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      listing: null,
      balances: null,
      time: (new Date()).getTime(),
      partialPassword: "",
    }
    every(60, () => this.setState({ ...this.state, time: (new Date()).getTime() }))
    every(60, () => {
      fetchJson("api/listdir").then((data: DirContents) => {
        this.setState({ ...this.state, listing: data });
      })
    })
    every(15, () => {
      fetchJson("api/balances").then((data: BalanceResponse) => {
        this.setState({ ...this.state, balances: data });
      })
    })
  }

  componentDidMount() {
    document.title = 'Abundance of Rain'
    document.addEventListener('keydown', (event) => {
      switch (event.keyCode) {
        case 48: case 192: gotoElement('0'); break;
        case 49: gotoElement('1'); break;
        case 50: gotoElement('2'); break;
        case 51: gotoElement('3'); break;
        case 52: gotoElement('4'); break;
        case 53: gotoElement('5'); break;
        case 54: gotoElement('6'); break;
        case 55: gotoElement('7'); break;
        case 56: gotoElement('8'); break;
        case 57: gotoElement('9'); break;
        default: break;
      }
    })
  }

  render() {
    return (
      <div className="App" id="0">
        <h4>Abundance of Rain</h4>
        <div>
          {this.state.balances && renderBalances(this.state.balances)}
        </div>
        <p>
          {renderListing(this.state.listing, this.state.time)}
        </p>
        <form>
          <input
            type="password"
            value={this.state.partialPassword}
            onChange={e => this.setState({ ...this.state, partialPassword: e.target.value })}/>
          <button type="submit" onClick={() => setPassword(this.state.partialPassword)}>Set password</button>
        </form>
      </div>
    )
  }
}

function gotoElement(id: string) {
  let element = document.getElementById(id);
  if (element) {
    element.scrollIntoView(true);
  }
}

function setPassword(password: string) {
  if (password) {
    window.localStorage.setItem("password", password);
    window.location.reload();
  }
}

function renderListing(listing: DirContents | null, time: number): React.ReactNode {
  if (!listing) {
    return null;
  }
  if (typeof listing === 'string') {
    throw new Error('root should not be string')
  }
  let result = []
  for (let index = 0; index < listing.contents.length; index++) {
    result.push(<div id={(index+1).toString()} key={index}>
      {paths(listing.contents[index]).map((path, pathIndex) => <div key={pathIndex}>
        <img src={`${REMOTE_HOST}/${listing.name}/${path}?time=${time}`}/>
      </div>)
    }
    </div>)
  }
  result.push(<div id={(listing.contents.length+1).toString()} key={listing.contents.length}/>)
  return result
}

function renderBalances({ balances, time }: BalanceResponse): React.ReactNode {
  return <div>
    <div>{time}</div>
    <div className="balances">
      {Object.keys(balances).map(ex => (
        <div key={ex}>
          <h4>{fancyExchangeName(ex)}</h4>
          <table>
            <tr>
              {Object.keys(balances[ex]).map(account => (
                <th>{account}</th>
              ))}
            </tr>
            <tr>
              {Object.keys(balances[ex]).map(account => (
                <td>
                  <table className="asset-table">
                    {sortAssets(Object.keys(balances[ex][account])).map((asset, index) => (
                      <tr key={asset}>
                        <td>{asset}</td>
                        <td>{roundBalance(balances[ex][account][asset])}</td>
                      </tr>
                    ))}
                  </table>
                </td>
              ))}
            </tr>
          </table>
        </div>
      ))}
    </div>
  </div>
}

function fancyExchangeName(ex: string): string {
  if (ex === 'BinPerp') {
    // return 'Binance Futures (USDT-margined)'
    return 'F'
  }
  if (ex == 'BinSpot') {
    // return 'Binance Spot'
    return 'S'
  }
  return ex
}

function sortAssets(assets: string[]): string[] {
  assets = assets.map(x => x);
  let ordering = [
    "USDT",
    "BUSD",
  ];
  for (let first of reverse(ordering)) {
    let index = assets.indexOf(first);
    if (index !== -1) {
      assets.splice(0, 0, assets.splice(index, 1)[0]);
    }
  }
  return assets
}

function reverse<T>(arr: T[]): T[] {
  return arr.map((_, i) => arr[arr.length - i - 1])
}

function roundBalance(balance: number): number {
  if (balance === 0) {
    return 0;
  }
  if (balance < 0) {
    return -roundBalance(-balance);
  }
  let mul = 1;
  while (balance * mul < 10) {
    mul *= 10;
  }
  return Math.round(balance * mul) / mul;
}

export default App;
